<template>
  <div class="df-between df-middle list f-md">
    <Time format="MM-dd-yy HH:mm:ss" :time="list.dataTime" class="time" />
    <p class="flex-1 ml-20">{{ list.pointSourceName }}</p>
    <p class="f-bold">{{ list.pointsValue > 0 ? '+' : '' }}{{ list.pointsValue }}</p>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import Time from '@/components/Time.vue'

export default defineComponent({
  name: 'ThePointsItem',
  components: { Time },
  props: {
    list: {
      type: Object,
      required: true,
    },
  },
})
</script>

<style scoped lang="scss">
  .list {
    height: 48px;
  }

  .time {
    min-width: 130px;
    max-width: 130px;
  }

</style>
