<template>
  <PageWithHeader class="pt-16">
    <template #title><t path="points_2">Points</t></template>
    <Scroller @bottomTouched="loadMore" class="fill px-16">
      <Holder :content="loaded" :skeleton-repeat="2">
        <List :list-data="loaded" #="{ item }">
          <ThePointsItem :list="item" />
        </List>
      </Holder>
    </Scroller>
  </PageWithHeader>
</template>

<script lang="ts">
import List from '@/components/AsyncNullableList.vue'
import { defineComponent } from 'vue'
import PageWithHeader from '@/provider/PageWithHeader.vue'
import useLoadMore from 'common/hooks/useLoadMore'
import Scroller from 'common/providers/Scroller.vue'
import Holder from '@/provider/Holder.vue'
import ThePointsItem from '@/modules/membership/components/ThePointsItem.vue'
import { readPoints, Points } from '@/modules/membership/member.api'

export default defineComponent({
  name: 'Points',
  components: { ThePointsItem, Holder, Scroller, PageWithHeader, List },
  setup () {
    const { loaded, refresh, loadMore } = useLoadMore<Points, Array<Points>>(
      readPoints, 20, resp => resp,
    )

    refresh()

    return {
      loaded,
      loadMore,
    }
  },
})
</script>
